<template lang="pug">
form.vc-order-billing-address-form.vc-order-address-form
  .section-label
    span {{ attributeLocaleText('order', 'billing_address') }}

    common-address-button(
      v-if="commonAddressHelper.isCommonAddresButtonShow.value"
      :address="billingAddress"
      shipping-type="billing"
      @selected-address="commonAddressHelper.selectCommonAddressHandler"
    )

  .form-section.has-sub-fields
    b-field(
      :label="attributeLocaleText('user/common_address', 'buyer_name')"
      :type="errors.errorClassAt('b_recipient')"
      :message="errors.get('b_recipient')"
    )
      b-input(
        type="text"
        name="name"
        autocomplete="name"
        v-model="billingAddress.recipient"
        @input="errors.clear('b_recipient')"
      )
    b-field(
      :label="attributeLocaleText('user/common_address', 'phone')"
      :type="errors.errorClassAt('b_phone')"
      :message="errors.get('b_phone')"
    )
      b-input(
        type="tel"
        autocomplete="tel"
        v-model="billingAddress.phone"
        @input="errors.clear('b_phone')"
      )

    template(v-if="invoiceServiceEnabled")
      b-field(
        :label="attributeLocaleText('user/common_address', 'invoice_type')"
        :type="errors.errorClassAt('b_invoice_type')"
        :message="errors.get('b_invoice_type')"
        :class="{ 'is-full': !carrierRequiredColumn }"
      )
        b-select(
          v-model="billingAddress.address.invoice_type"
          @input="errors.clear('b_invoice_type')"
        )
          option(
            v-for="(invoiceType, index) in invoiceTypes"
            :key="index"
            :value="invoiceType.key"
          ) {{ invoiceType.name }}
      b-field(
        v-if="carrierRequiredColumn === 'carrier_number'"
        :label="attributeLocaleText('user/common_address', 'carrier_number')"
        :type="errors.errorClassAt('b_carrier_number')"
        :message="errors.get('b_carrier_number')"
      )
        b-input(
          v-model="billingAddress.address.carrier_number"
          @input="errors.clear('b_carrier_number')"
        )
      b-field.love-code(
        v-if="carrierRequiredColumn === 'love_code'"
        :type="errors.errorClassAt('b_love_code')"
        :message="errors.get('b_love_code')"
      )
        a.label(
          href="#"
          @click.prevent="openLoveCodeSelector"
        )
          span {{ attributeLocaleText('user/common_address', 'love_code') }}
          span.select-code
            .icon
              i.fa.fa-search
            span 捐贈碼查詢
        b-input(
          v-model="billingAddress.address.love_code"
          @input="errors.clear('b_love_code')"
          @click.native="openLoveCodeSelector"
          disabled
        )

    template(v-if="companyAndEinEnabled")
      b-field(
        :label="attributeLocaleText('user/common_address', 'company') + ` (${messageLocaleText('optional')})`"
        :type="errors.errorClassAt('b_company')"
        :message="errors.get('b_company')"
      )
        b-input(
          type="text"
          name="company"
          autocomplete="organization"
          v-model="billingAddress.company"
          @input="errors.clear('b_company')"
        )
      b-field(
        :label="attributeLocaleText('user/common_address', 'ein') + ` (${messageLocaleText('optional')})`"
        :type="errors.errorClassAt('b_ein')"
        :message="errors.get('b_ein')"
      )
        b-input(
          type="text"
          v-model="billingAddress.ein"
          autocomplete="ein"
          @input="errors.clear('b_ein')"
        )

    b-field(
      :label="attributeLocaleText('user/common_address', 'city')"
      :type="errors.errorClassAt('b_city')"
      :message="errors.get('b_city')"
    )
      city-select(
        :city.sync="billingAddress.address.city"
        @input="errors.clear('b_city')"
      )

    b-field(
      :label="attributeLocaleText('user/common_address', 'area')"
      :type="errors.errorClassAt('b_area')"
      :message="errors.get('b_area')"
    )
      area-select(
        :city="billingAddress.address.city"
        :area.sync="billingAddress.address.area"
        :zip.sync="billingAddress.address.zip"
        autocomplete="region"
        @input="errors.clear('b_area')"
      )

    b-field.is-full(
      :label="attributeLocaleText('user/common_address', 'street_address')"
      :type="errors.errorClassAt('b_street_address')"
      :message="errors.get('b_street_address')"
    )
      b-input(
        type="text"
        v-model="billingAddress.address.street_address"
        autocomplete="street-address"
        @input="errors.clear('b_street_address')"
      )

    save-address-as-common-field(
      v-if="commonAddressHelper.isSaveAddressAsCommonShow.value"
      :save-address-as-common="saveBillingAddressAsCommoon"
      @update:save-address-as-common="saveBillingAddressAsCommoonHandler"
    )
</template>

<script>
import { defineComponent, computed, watch } from 'vue'
import { useStore, useInstance } from 'skid-composables'
import CommonAddressButton from './common-address-button.vue'
import SaveAddressAsCommonField from './save-address-as-common-field.vue'
import CitySelect from '@sharedComponents/city-select.vue'
import AreaSelect from '@sharedComponents/area-select.vue'
import useCommonAddressHelper from './common-address-helper.js'

export default defineComponent({
  components: {
    CommonAddressButton,
    SaveAddressAsCommonField,
    CitySelect,
    AreaSelect
  },

  props: {
    billingAddress: {
      type: Object,
      required: true
    },

    saveBillingAddressAsCommoon: {
      type: Boolean,
      required: true
    }
  },

  setup(props, { emit }) {
    const store = useStore()
    const vueInstance = useInstance()

    const invoiceServiceEnabled = computed(() => {
      return store.getters['siteConfigs/invoiceServiceEnabled']
    })

    const invoiceTypes = computed(() => {
      return store.getters['siteConfigs/invoiceTypes']
    })

    const companyAndEinEnabled = computed(() => {
      if (store.getters['siteConfigs/disableEinInput']) return false

      if (invoiceTypes.value.length === 0) return true

      return !props.billingAddress.address.invoice_type
    })

    const carrierRequiredColumn = computed(() => {
      return invoiceTypes.value.find(
        (type) => type.key === props.billingAddress.address.invoice_type
      )?.required_column
    })

    const errors = computed(() => store.getters['users/errors'])

    // common address
    const commonAddressHelper = useCommonAddressHelper({
      addressForm: props.billingAddress,
      isReferenceFromOtherForm: () => false,
      addressChangedHandler: () => {
        saveBillingAddressAsCommoonHandler(true)
      }
    })

    const saveBillingAddressAsCommoonHandler = (value) => {
      emit('update:save-billing-address-as-commoon', value)
    }

    watch(
      () => props.billingAddress.recipient,
      (newValue) => emit('update:recipient', newValue)
    )

    watch(
      () => props.billingAddress.phone,
      (newValue) => emit('update:phone', newValue)
    )

    return {
      commonAddressHelper,
      invoiceServiceEnabled,
      companyAndEinEnabled,
      carrierRequiredColumn,
      invoiceTypes,
      saveBillingAddressAsCommoonHandler,
      errors
    }
  }
})
</script>
